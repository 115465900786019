<template>
  <div class="gallery-card-warren-gallery-card" v-bind:class="rootClassName">
    <img
      :alt="image_alt"
      :src="image_src"
      loading="eager"
      class="gallery-card-warren-image button"
    />
    <h2 class="gallery-card-warren-text">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'GalleryCardWarren',
  props: {
    title: {
      type: String,
      default: 'Warren Fusion Power Plant',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    image_src: {
      type: String,
      default: '/playground_assets/limerick-generating-station-1500h.png',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.gallery-card-warren-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card-warren-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-card-warren-text {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}

@media(max-width: 1200px) {
  .gallery-card-warren-image {
    border-style: solid;
  }
}
@media(max-width: 767px) {
  .gallery-card-warren-gallery-card {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .gallery-card-warren-image {
    height: 10rem;
  }
}
</style>
