<template>
  <div class="article-template-container">
    <site-header rootClassName="site-header-root-class-name6"></site-header>
    <h1 class="article-template-date">publish date</h1>
    <h1 class="article-template-title">article title</h1>
    <span class="article-template-body">
      <span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        pharetra id sem sit amet vehicula. Suspendisse bibendum tristique mi, non
        ultrices tortor. Phasellus laoreet aliquam porttitor. In elementum urna
        sit amet cursus consectetur. Aenean quis dignissim tortor. Sed sed lorem
        sodales, aliquet velit at, feugiat mauris. Morbi ut orci dignissim,
        iaculis metus et, egestas mi. Suspendisse varius, leo ac varius rhoncus,
        purus urna posuere ipsum, in iaculis ex velit eget est. Ut dolor libero,
        maximus id varius vel, accumsan et eros. Fusce ac tellus enim. Nunc mattis
        erat eget erat hendrerit tincidunt. Donec eleifend imperdiet tortor sed
        volutpat. Ut facilisis venenatis finibus. Maecenas a congue diam. Aenean
        commodo ipsum sed libero ullamcorper facilisis.
      </span>
      <br />
      <br />
      <span>
        Morbi bibendum mi ultrices tortor bibendum tempor. Curabitur odio nisl,
        vestibulum eu nunc at, imperdiet euismod tortor. Morbi nec purus eleifend,
        hendrerit erat a, luctus nulla. Ut est diam, lacinia scelerisque lectus
        et, dapibus ultricies tellus. Aenean mattis mi id tristique tincidunt.
        Cras eu fermentum lorem. Ut odio nisl, vehicula in nibh a, congue
        ultricies sapien. Nam pharetra ornare tincidunt. Fusce neque sem, tempus
        feugiat volutpat sit amet, maximus et sapien. Class aptent taciti sociosqu
        ad litora torquent per conubia nostra, per inceptos himenaeos. Sed quis
        pretium massa. Phasellus eleifend odio et lectus ullamcorper hendrerit.
        Pellentesque habitant morbi tristique senectus et netus et malesuada fames
        ac turpis egestas. Nulla purus turpis, dictum non tellus ac, dapibus
        pellentesque lorem.
      </span>
      <br />
      <br />
      <span>
        Donec non tristique diam. Morbi eleifend viverra risus sit amet mattis.
        Phasellus ut lacinia odio, ut commodo elit. Morbi sit amet interdum massa.
        Integer in purus quis orci dignissim porttitor in nec sapien. Integer sed
        luctus justo. Morbi enim ex, blandit nec viverra quis, sagittis
        sollicitudin risus. Cras porta eu arcu a tempus.
      </span>
      <br />
      <br />
      <span>
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia curae; Vestibulum sit amet ante at turpis pellentesque viverra ut
        quis felis. Pellentesque habitant morbi tristique senectus et netus et
        malesuada fames ac turpis egestas. Proin malesuada, mi ac imperdiet
        consequat, velit leo dignissim nibh, sagittis convallis metus diam non
        erat. Duis vel magna nec metus vestibulum rutrum. Nullam sit amet lacus
        tristique, pharetra massa non, egestas magna. Suspendisse quis nulla
        cursus, rhoncus dui consectetur, convallis dui. Vestibulum quis metus in
        turpis aliquam porttitor vitae ac ipsum. Praesent varius sodales quam,
        viverra accumsan est molestie a. Aliquam malesuada mauris id leo dapibus
        molestie. Proin ac nibh porta dui luctus vehicula.
      </span>
      <br />
      <br />
      <span>
        Ut venenatis, neque quis imperdiet tempus, metus nisl mattis purus, nec
        congue sapien libero et felis. Nulla mollis accumsan orci vel tincidunt.
        Pellentesque vehicula metus a placerat scelerisque. Vivamus non maximus
        enim, nec suscipit sem. Ut consectetur arcu id elit varius interdum. Fusce
        consequat gravida augue a bibendum. Etiam a est eget libero accumsan
        tincidunt. Aenean eros risus, rhoncus sit amet fringilla ac, facilisis vel
        eros. Morbi at nibh at magna auctor congue.
      </span>
    </span>
    <site-footer rootClassName="site-footer-root-class-name3"></site-footer>
  </div>
</template>

<script>
import SiteHeader from '../components/site-header'
import SiteFooter from '../components/site-footer'

export default {
  name: 'ArticleTemplate',
  components: {
    SiteHeader,
    SiteFooter,
  },
  metaInfo: {
    title: 'ArticleTemplate - Opifex',
    meta: [
      {
        name: 'description',
        content: "you're not supposed to be here",
      },
      {
        property: 'og:title',
        content: 'ArticleTemplate - Opifex',
      },
      {
        property: 'og:description',
        content: 'Striving for sustainable energy.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.article-template-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-black);
}
.article-template-date {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 400;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: var(--dl-space-space-fourunits);
  text-transform: capitalize;
}
.article-template-title {
  color: var(--dl-color-primary-100);
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
  text-transform: uppercase;
}
.article-template-body {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-fourunits);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
@media(max-width: 1200px) {
  .article-template-container {
    background-color: var(--dl-color-gray-black);
  }
  .article-template-date {
    color: var(--dl-color-gray-white);
    align-self: flex-start;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
  .article-template-title {
    color: var(--dl-color-primary-100);
    text-transform: uppercase;
  }
  .article-template-body {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
}
@media(max-width: 991px) {
  .article-template-date {
    font-size: 1.5em;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
}
</style>
