<template>
  <div class="article-net-zero2025-container">
    <site-header rootClassName="site-header-root-class-name8"></site-header>
    <h1 class="article-net-zero2025-date">April 12th 2023</h1>
    <h1 class="article-net-zero2025-title">
      <span class="article-net-zero2025-text">
        Innovations in fusion power promise clean future
      </span>
      <br />
    </h1>
    <span class="article-net-zero2025-body">
      <span>
        The leading provider of advanced energy solutions; Opifex Industries, is
        making waves in the energy industry with two new U.S-based fusion
        reactors. Paving the way for a cleaner, more sustainable future.
      </span>
      <br />
      <br />
      <span>
        Fusion power is the process of generating energy by fusing together atomic
        nuclei, and it has long been seen as a promising alternative to
        traditional energy sources such as coal or natural gas. While fusion power
        has been under development for decades, recent breakthroughs in the field
        have brought the technology closer to reality than ever before.
      </span>
      <br />
      <br />
      <span>
        Opifex Industries is at the forefront of this research, with a track
        record of groundbreaking innovations in fusion power. The company
        currently operates one fusion power plant, and with the construction of
        two new plants in the United States, Opifex Industries is expanding its
        capacity to generate clean, sustainable energy.
      </span>
      <br />
      <br />
      <span>
        &quot;We believe that fusion power holds tremendous potential for meeting
        the world&apos;s energy needs in a sustainable way,&quot; said Dr.
        Christopher Richardson, Chief Technology Officer of Opifex Industries.
        &quot;With these new plants, we are taking a major step forward in making
        that potential a reality.&quot;
      </span>
      <br />
      <br />
      <span>
        In addition to its plans for new fusion power plants, Opifex Industries is
        also committed to phasing out its consumption of fossil fuels. The company
        currently operates a number of conventional nuclear fission power plants,
        as well as wind and coal power plants. However, with the increasing
        emphasis on sustainability and reducing carbon emissions, Opifex
        Industries is investing heavily in the development of alternative energy
        sources.
      </span>
      <br />
      <br />
      <span>
        &quot;We recognize that our current energy portfolio includes some
        traditional sources of energy that are not sustainable in the long
        term,&quot; said Dr. Richardson. &quot;That&apos;s why we&apos;re
        committed to reducing our reliance on fossil fuels and transitioning to
        cleaner, more sustainable sources of energy.&quot;
      </span>
      <br />
      <br />
      <span>
        Overall, Opifex Industries is leading the way in the development of
        advanced energy solutions that promise to transform the way we power our
        world. With its groundbreaking research in fusion power and commitment to
        sustainable energy, the company is paving the way for a cleaner, more
        sustainable future for us all.&quot;
      </span>
    </span>
    <site-footer rootClassName="site-footer-root-class-name5"></site-footer>
  </div>
</template>

<script>
import SiteHeader from '../components/site-header'
import SiteFooter from '../components/site-footer'

export default {
  name: 'ArticleNetZero2025',
  components: {
    SiteHeader,
    SiteFooter,
  },
  metaInfo: {
    title: 'NET ZERO 2025',
    meta: [
      {
        name: 'description',
        content: 'Our plan and roadmap for reducing our carbon footprint.',
      },
      {
        property: 'og:title',
        content: 'NET ZERO 2025',
      },
      {
        property: 'og:description',
        content: 'Our plan and roadmap for reducing our carbon footprint.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.article-net-zero2025-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-black);
}
.article-net-zero2025-date {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 400;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: var(--dl-space-space-fourunits);
  text-transform: capitalize;
}
.article-net-zero2025-title {
  color: var(--dl-color-primary-100);
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
  text-transform: uppercase;
}
.article-net-zero2025-body {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  margin-top: var(--dl-space-space-tripleunit);
  text-align: left;
  font-weight: 500;
  margin-left: var(--dl-space-space-tripleunit);
  margin-right: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-tripleunit);
}
@media(max-width: 1200px) {
  .article-net-zero2025-container {
    background-color: var(--dl-color-gray-black);
  }
  .article-net-zero2025-date {
    color: var(--dl-color-gray-white);
    align-self: flex-start;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
  .article-net-zero2025-title {
    color: var(--dl-color-primary-100);
  }
  .article-net-zero2025-text {
    color: var(--dl-color-primary-100);
  }
  .article-net-zero2025-body {
    color: var(--dl-color-gray-white);
  }
}
@media(max-width: 991px) {
  .article-net-zero2025-date {
    font-size: 1.5em;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
}
</style>
