<template>
  <div class="haines-container">
    <site-header rootClassName="site-header-root-class-name1"></site-header>
    <div class="haines-container1">
      <div class="haines-container2">
        <h1 class="haines-text">Haines Nuclear Power Plant</h1>
      </div>
      <span class="haines-text1">
        The Haines Nuclear Power Plant is situated in southern Alaska. It is the
        most efficient power plant in the state of Alaska, and provides power to
        many towns in the area, in what is ordinarily a remote location.
      </span>
      <img
        alt="image"
        src="/playground_assets/haines-1500w.jpg"
        class="haines-image"
      />
    </div>
    <site-footer rootClassName="site-footer-root-class-name"></site-footer>
  </div>
</template>

<script>
import SiteHeader from '../components/site-header'
import SiteFooter from '../components/site-footer'

export default {
  name: 'Haines',
  components: {
    SiteHeader,
    SiteFooter,
  },
  metaInfo: {
    title: 'Opifex - Haines',
    meta: [
      {
        name: 'description',
        content: 'Powering Alaska for tomorrow',
      },
      {
        property: 'og:title',
        content: 'Opifex - Haines',
      },
      {
        property: 'og:description',
        content: 'Powering Alaska for the future',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.haines-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.haines-container1 {
  width: 1372px;
  height: 850px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.haines-container2 {
  flex: 0 0 auto;
  width: 608px;
  height: 71px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.haines-text {
  align-self: flex-start;
}
.haines-text1 {
  font-size: 30px;
  align-self: center;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.haines-image {
  width: 100%;
  height: 627px;
  align-self: center;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .haines-container1 {
    width: 100%;
  }
}
</style>
