<template>
  <div class="product-showcase-funnel-container">
    <site-header rootClassName="site-header-root-class-name12"></site-header>
    <h1 class="product-showcase-funnel-title">Gravitonic Conveyance Beam</h1>
    <div class="product-showcase-funnel-div">
      <DangerousHTML
        html="<iframe width='560' height='315' src='https://www.youtube.com/embed/G1N7hBgu2Nk' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen></iframe>"
      ></DangerousHTML>
    </div>
    <span class="product-showcase-funnel-body">
      <span>
        The Gravitonic Conveyance Beam, also known as the Funnel, is a
        groundbreaking technology developed by Opifex Industries that has the
        potential to revolutionize the way we transport objects. The Funnel works
        by surrounding an object with Gravitons, which are theoretical particles
        that are believed to be associated with gravity. These Gravitons do not
        change the gravity of an object but instead redirect its inertia.
      </span>
      <br />
      <br />
      <span>
        The potential applications of this technology are immense. For example,
        the Funnel could be used to transport heavy machinery or large structures
        that would otherwise be impossible to move using conventional methods. The
        technology could also have significant implications for the transportation
        of hazardous materials, as it would eliminate the need for risky
        transportation methods such as road or air transport.
      </span>
      <br />
      <br />
      <span>
        Opifex Industries has already begun testing the Funnel on a variety of
        objects, and the results have been nothing short of astounding. The device
        has proven to be incredibly versatile, and it has been able to transport
        everything from small items to large vehicles with ease. The Funnel has
        also been tested in extreme weather conditions, including high winds and
        heavy rain, and has continued to perform flawlessly.
      </span>
      <br />
      <br />
      <span>
        In addition to its transport capabilities, the Funnel could also be used
        for a variety of other applications. For example, it could be used to move
        objects in space, where traditional propulsion methods are less effective.
        The technology could also be used in construction, allowing workers to
        move heavy equipment and materials more efficiently and safely.
      </span>
      <br />
      <br />
      <span>
        In conclusion, the Gravitonic Conveyance Beam, or Funnel, is a
        game-changing technology that has the potential to transform the way we
        transport objects. Its versatility and reliability make it an exciting
        development in the field of transportation, and Opifex Industries is proud
        to be at the forefront of this groundbreaking technology.
      </span>
    </span>
    <site-footer rootClassName="site-footer-root-class-name8"></site-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import SiteHeader from '../components/site-header'
import SiteFooter from '../components/site-footer'

export default {
  name: 'ProductShowcaseFunnel',
  components: {
    SiteHeader,
    DangerousHTML,
    SiteFooter,
  },
  metaInfo: {
    title: 'ProductShowcaseFunnel - Opifex',
    meta: [
      {
        name: 'description',
        content: "you're not supposed to be here",
      },
      {
        property: 'og:title',
        content: 'ProductShowcaseFunnel - Opifex',
      },
      {
        property: 'og:description',
        content: 'Striving for sustainable energy.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.product-showcase-funnel-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-black);
}
.product-showcase-funnel-title {
  color: var(--dl-color-primary-100);
  font-size: 4em;
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
  text-transform: uppercase;
}
.product-showcase-funnel-div {
  width: 584px;
}
.product-showcase-funnel-body {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-fourunits);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
@media(max-width: 1200px) {
  .product-showcase-funnel-container {
    background-color: var(--dl-color-gray-black);
  }
  .product-showcase-funnel-title {
    color: var(--dl-color-primary-100);
    font-size: 3em;
  }
  .product-showcase-funnel-div {
    align-self: center;
  }
  .product-showcase-funnel-body {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
}
</style>
