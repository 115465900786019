<template>
  <div class="gallery-card-haines-gallery-card" v-bind:class="rootClassName">
    <img
      :alt="image_alt"
      :src="image_src"
      loading="eager"
      class="gallery-card-haines-image button"
    />
    <h2 class="gallery-card-haines-text">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'GalleryCardHaines',
  props: {
    rootClassName: String,
    image_alt: {
      type: String,
      default: 'image',
    },
    image_src: {
      type: String,
      default: '/playground_assets/20220717143305_1-1500h.jpg',
    },
    title: {
      type: String,
      default: 'Haines Nuclear Power Plant',
    },
  },
}
</script>

<style scoped>
.gallery-card-haines-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card-haines-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-card-haines-text {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}

@media(max-width: 767px) {
  .gallery-card-haines-gallery-card {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .gallery-card-haines-image {
    height: 10rem;
  }
}
</style>
