<template>
  <div class="product-showcase-template-container">
    <site-header rootClassName="site-header-root-class-name10"></site-header>
    <h1 class="product-showcase-template-title">product name</h1>
    <div class="product-showcase-template-div">
      <DangerousHTML
        html="<iframe width='560' height='315' src='https://www.youtube.com/embed/G1N7hBgu2Nk' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen></iframe>"
      ></DangerousHTML>
    </div>
    <span class="product-showcase-template-body">
      <span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        pharetra id sem sit amet vehicula. Suspendisse bibendum tristique mi, non
        ultrices tortor. Phasellus laoreet aliquam porttitor. In elementum urna
        sit amet cursus consectetur. Aenean quis dignissim tortor. Sed sed lorem
        sodales, aliquet velit at, feugiat mauris. Morbi ut orci dignissim,
        iaculis metus et, egestas mi. Suspendisse varius, leo ac varius rhoncus,
        purus urna posuere ipsum, in iaculis ex velit eget est. Ut dolor libero,
        maximus id varius vel, accumsan et eros. Fusce ac tellus enim. Nunc mattis
        erat eget erat hendrerit tincidunt. Donec eleifend imperdiet tortor sed
        volutpat. Ut facilisis venenatis finibus. Maecenas a congue diam. Aenean
        commodo ipsum sed libero ullamcorper facilisis.
      </span>
      <br />
      <br />
      <span>
        Morbi bibendum mi ultrices tortor bibendum tempor. Curabitur odio nisl,
        vestibulum eu nunc at, imperdiet euismod tortor. Morbi nec purus eleifend,
        hendrerit erat a, luctus nulla. Ut est diam, lacinia scelerisque lectus
        et, dapibus ultricies tellus. Aenean mattis mi id tristique tincidunt.
        Cras eu fermentum lorem. Ut odio nisl, vehicula in nibh a, congue
        ultricies sapien. Nam pharetra ornare tincidunt. Fusce neque sem, tempus
        feugiat volutpat sit amet, maximus et sapien. Class aptent taciti sociosqu
        ad litora torquent per conubia nostra, per inceptos himenaeos. Sed quis
        pretium massa. Phasellus eleifend odio et lectus ullamcorper hendrerit.
        Pellentesque habitant morbi tristique senectus et netus et malesuada fames
        ac turpis egestas. Nulla purus turpis, dictum non tellus ac, dapibus
        pellentesque lorem.
      </span>
      <br />
    </span>
    <site-footer rootClassName="site-footer-root-class-name6"></site-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import SiteHeader from '../components/site-header'
import SiteFooter from '../components/site-footer'

export default {
  name: 'ProductShowcaseTemplate',
  components: {
    SiteHeader,
    DangerousHTML,
    SiteFooter,
  },
  metaInfo: {
    title: 'ProductShowcaseTemplate - Opifex',
    meta: [
      {
        name: 'description',
        content: "you're not supposed to be here",
      },
      {
        property: 'og:title',
        content: 'ProductShowcaseTemplate - Opifex',
      },
      {
        property: 'og:description',
        content: 'Striving for sustainable energy.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.product-showcase-template-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-black);
}
.product-showcase-template-title {
  color: var(--dl-color-primary-100);
  font-size: 4em;
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
  text-transform: uppercase;
}
.product-showcase-template-div {
  width: 584px;
}
.product-showcase-template-body {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-fourunits);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
@media(max-width: 1200px) {
  .product-showcase-template-container {
    background-color: var(--dl-color-gray-black);
  }
  .product-showcase-template-title {
    color: var(--dl-color-primary-100);
    font-size: 3em;
    text-transform: uppercase;
  }
  .product-showcase-template-div {
    align-self: center;
  }
  .product-showcase-template-body {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
}
</style>
