<template>
  <div class="gallery-card-mc-gregor-gallery-card" v-bind:class="rootClassName">
    <img
      :alt="image_alt"
      :src="image_src"
      loading="eager"
      class="gallery-card-mc-gregor-image button"
    />
    <h2 class="gallery-card-mc-gregor-text">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'GalleryCardMcGregor',
  props: {
    image_src: {
      type: String,
      default: '/playground_assets/mcgregor-1500h.jpg',
    },
    rootClassName: String,
    image_alt: {
      type: String,
      default: 'image',
    },
    title: {
      type: String,
      default: 'McGregor Research Facility',
    },
  },
}
</script>

<style scoped>
.gallery-card-mc-gregor-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card-mc-gregor-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-card-mc-gregor-text {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}

@media(max-width: 767px) {
  .gallery-card-mc-gregor-gallery-card {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .gallery-card-mc-gregor-image {
    height: 10rem;
  }
}
</style>
