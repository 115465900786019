<template>
  <div class="notfound-container">
    <site-header rootClassName="site-header-root-class-name2"></site-header>
    <div class="notfound-container1">
      <h1 class="notfound-text">
        <span>ERROR 404</span>
        <br />
      </h1>
      <span>Sorry, that page cannot be found.</span>
      <router-link to="/" class="notfound-navlink button">
        <span>
          <span>Return home</span>
          <br />
        </span>
      </router-link>
    </div>
    <site-footer rootClassName="site-footer-root-class-name2"></site-footer>
  </div>
</template>

<script>
import SiteHeader from '../components/site-header'
import SiteFooter from '../components/site-footer'

export default {
  name: 'NOTFOUND',
  components: {
    SiteHeader,
    SiteFooter,
  },
  metaInfo: {
    title: 'Error 404',
    meta: [
      {
        name: 'description',
        content: 'Page not found.',
      },
      {
        property: 'og:title',
        content: 'Error 404',
      },
      {
        property: 'og:description',
        content: 'Page not found.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.notfound-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.notfound-container1 {
  width: 1322px;
  height: 714px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.notfound-text {
  align-self: center;
}
.notfound-navlink {
  text-decoration: none;
}
@media(max-width: 1200px) {
  .notfound-container1 {
    gap: var(--dl-space-space-doubleunit);
    flex: 1;
    width: 100%;
    height: auto;
    align-self: stretch;
  }
  .notfound-navlink {
    border-color: var(--dl-color-gray-white);
    border-width: 2px;
    border-radius: 8px;
  }
}
@media(max-width: 767px) {
  .notfound-container1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .notfound-container1 {
    width: 100%;
  }
}
</style>
