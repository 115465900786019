<template>
  <nav class="navigation-links-nav" v-bind:class="rootClassName">
    <router-link to="/team" class="navigation-links-text">{{ text }}</router-link>
    <router-link to="/contact" class="navigation-links-navlink">
      {{ text1 }}
    </router-link>
    <a
      href="https://twitter.com/OpifexPower"
      target="_blank"
      rel="noreferrer noopener"
      class="navigation-links-link"
    >
      {{ text2 }}
    </a>
  </nav>
</template>

<script>
export default {
  name: 'NavigationLinks',
  props: {
    text: {
      type: String,
      default: 'Our Team',
    },
    text1: {
      type: String,
      default: 'Contact',
    },
    text2: {
      type: String,
      default: 'Social Media',
    },
    text4: {
      type: String,
      default: 'Employee Login',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.navigation-links-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links-text {
  text-decoration: none;
}
.navigation-links-navlink {
  margin-left: 32px;
  text-decoration: none;
}
.navigation-links-link {
  margin-left: 32px;
  text-decoration: none;
}


















.navigation-links-root-class-name20 {
  align-self: center;
}

@media(max-width: 767px) {
  .navigation-links-nav {
    align-items: flex-start;
    flex-direction: column;
  }
  .navigation-links-text {
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-navlink {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
</style>
