<template>
  <div class="feature-card-research-feature-card" v-bind:class="rootClassName">
    <h2 class="feature-card-research-text">{{ title }}</h2>
    <span class="feature-card-research-text1">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: 'FeatureCardResearch',
  props: {
    rootClassName: String,
    title: {
      type: String,
      default: 'Research',
    },
    description: {
      type: String,
      default:
        'Opifex’s vast team of researchers are at the core of the company. Creating cleaner, safer ways to generate power, and also making many advanced scientific discoveries.',
    },
  },
}
</script>

<style scoped>
.feature-card-research-feature-card {
  width: 100%;
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
}
.feature-card-research-text {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card-research-text1 {
  color: var(--dl-color-gray-700);
  text-align: center;
}

@media(max-width: 1200px) {
  .feature-card-research-text1 {
    color: var(--dl-color-gray-black);
  }
}
</style>
