<template>
  <div class="home-container">
    <site-header
      image_src1="/playground_assets/opifex%20industrieshq-200h.png"
      rootClassName="site-header-root-class-name5"
    ></site-header>
    <div class="home-banner">
      <h1 class="home-text">
        <span class="home-text01">NET ZERO 2025</span>
        <br class="home-text02" />
        <span class="home-text03">
          INNOVATIONS IN FUSION PROMISES CLEAN FUTURE
        </span>
      </h1>
      <router-link to="/article/netzero" class="home-navlink button">
        <span>
          <span class="home-text05">Learn More</span>
          <br />
        </span>
      </router-link>
    </div>
    <div class="home-features">
      <h1 class="home-text07">Research for a sustainable future</h1>
      <span class="home-text08">
        Here at Opifex, We strive to operate our power plants with great
        efficiency and safety.
      </span>
      <div class="home-container1">
        <feature-card-tech rootClassName="rootClassName"></feature-card-tech>
        <feature-card-power
          rootClassName="feature-card-power-root-class-name"
        ></feature-card-power>
        <feature-card-research
          rootClassName="feature-card-research-root-class-name"
        ></feature-card-research>
        <feature-card-community
          rootClassName="feature-card-community-root-class-name"
        ></feature-card-community>
      </div>
    </div>
    <div class="home-gallery">
      <router-link to="/facilities/warren">
        <gallery-card-warren
          rootClassName="rootClassName"
          class="home-component5"
        ></gallery-card-warren>
      </router-link>
      <router-link to="/facilities/haines">
        <gallery-card-haines
          rootClassName="gallery-card-haines-root-class-name"
          class="home-component6"
        ></gallery-card-haines>
      </router-link>
      <router-link to="/facilities/mc-gregor">
        <gallery-card-mc-gregor
          rootClassName="gallery-card-mc-gregor-root-class-name"
          class="home-component7"
        ></gallery-card-mc-gregor>
      </router-link>
    </div>
    <div class="home-banner1">
      <h1 class="home-text09">The Problem With Power</h1>
      <span class="home-text10">
        <span class="home-text11">
          The US is powered by many different types of power plants, from burning
          Coal, to Splitting the Atom, Some of which release more CO2 than others.
          <span v-html="raw2ohn"></span>
        </span>
        <br class="home-text12" />
        <span class="home-text13">
          The US power grid is complicated. And with complexity, comes risk.
          <span v-html="rawey0c"></span>
        </span>
        <br class="home-text14" />
        <span class="home-text15">
          At Opifex, We prioritize the construction of clean, efficient power
          plants, as well as making existing facilities cleaner.
        </span>
        <span><span v-html="raw3vub"></span></span>
        <span><span v-html="rawhduc"></span></span>
      </span>
      <router-link to="/article/problemwithpower" class="home-navlink4 button">
        Read More
      </router-link>
    </div>
    <div class="home-hero">
      <img
        alt="image"
        src="/playground_assets/1200px-coal_power_plant_datteln_2_crop1-1500w.png"
        class="home-image"
      />
      <div class="home-container2">
        <h1 class="home-text18">Let’s Work Together</h1>
        <h2 class="home-text19">Innovating power generation for the future.</h2>
        <div class="home-btn-group">
          <router-link to="/contact" class="home-navlink5 button">
            <span>
              <span class="home-text21">Contact Us</span>
              <br />
            </span>
          </router-link>
        </div>
        <span class="home-text23">
          <span>
            We’re always looking for new opportunities and people who can help
            shape the planet’s future. Please get in touch, and one of our project
            managers will contact you about the proposal process.
          </span>
          <span><span v-html="raw5aaj"></span></span>
          <span><span v-html="rawykfs"></span></span>
        </span>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import SiteHeader from '../components/site-header'
import FeatureCardTech from '../components/feature-card-tech'
import FeatureCardPower from '../components/feature-card-power'
import FeatureCardResearch from '../components/feature-card-research'
import FeatureCardCommunity from '../components/feature-card-community'
import GalleryCardWarren from '../components/gallery-card-warren'
import GalleryCardHaines from '../components/gallery-card-haines'
import GalleryCardMcGregor from '../components/gallery-card-mc-gregor'
import SiteFooter from '../components/site-footer'

export default {
  name: 'Home',
  components: {
    SiteHeader,
    FeatureCardTech,
    FeatureCardPower,
    FeatureCardResearch,
    FeatureCardCommunity,
    GalleryCardWarren,
    GalleryCardHaines,
    GalleryCardMcGregor,
    SiteFooter,
  },
  data() {
    return {
      raw2ohn: ' ',
      rawey0c: ' ',
      raw3vub: ' ',
      rawhduc: ' ',
      raw5aaj: ' ',
      rawykfs: ' ',
    }
  },
  metaInfo: {
    title: 'Opifex Industries',
    meta: [
      {
        name: 'description',
        content:
          'Opifex Industries strives to provide clean, reliable energy to the USA.',
      },
      {
        property: 'og:title',
        content: 'Opifex Industries',
      },
      {
        property: 'og:description',
        content: 'Striving for sustainable energy.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-banner {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 48px;
  margin-bottom: 0px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/playground_assets/nuclear-1500w.jpg");
  background-position: center;
}
.home-text {
  height: 100%;
  font-size: 3rem;
  align-self: center;
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-text01 {
  font-size: 4rem;
}
.home-text02 {
  font-size: 2rem;
}
.home-text03 {
  font-size: 2rem;
}
.home-navlink {
  width: 296px;
  height: 99px;
  align-self: center;
  padding-right: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.home-text05 {
  font-size: 50px;
}
.home-features {
  width: 100%;
  height: 674px;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: center;
  padding-top: 48px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/unsplash-image-g66k_erzrhm-1600h.jpg");
}
.home-text07 {
  color: var(--dl-color-primary-300);
  font-size: 3rem;
  margin-bottom: 32px;
}
.home-text08 {
  color: var(--dl-color-primary-500);
  width: 70%;
  font-size: 2rem;
  text-align: center;
}
.home-container1 {
  width: 100%;
  display: grid;
  grid-gap: 32px;
  margin-top: var(--dl-space-space-fourunits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-gallery {
  width: 100%;
  height: 610px;
  display: grid;
  padding: 48px;
  grid-gap: var(--dl-space-space-unit);
  max-width: 1400px;
  background-size: cover;
  background-image: url("/playground_assets/unsplash-image-yetqklnhsui-1400w.jpg");
  grid-template-columns: 1fr 1fr 1fr;
}
.home-component5 {
  text-decoration: none;
}
.home-component6 {
  text-decoration: none;
}
.home-component7 {
  text-decoration: none;
}
.home-banner1 {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-text09 {
  color: var(--dl-color-primary-500);
  font-size: 3rem;
  text-align: center;
}
.home-text10 {
  color: var(--dl-color-primary-300);
  max-width: 1400px;
  margin-top: 32px;
  text-align: center;
  margin-bottom: 32px;
}
.home-text11 {
  font-size: 20px;
}
.home-text12 {
  font-size: 20px;
}
.home-text13 {
  font-size: 20px;
}
.home-text14 {
  font-size: 20px;
}
.home-text15 {
  font-size: 20px;
}
.home-navlink4 {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-100);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink4:hover {
  transform: scale(1.02);
}
.home-hero {
  width: 100%;
  display: flex;
  padding: 32px;
  max-width: 1400px;
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 25rem;
  height: 685px;
  object-fit: cover;
}
.home-container2 {
  display: flex;
  margin-left: 32px;
  flex-direction: column;
}
.home-text18 {
  font-size: 3rem;
}
.home-text19 {
  margin-top: var(--dl-space-space-unit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.home-btn-group {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-navlink5 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.home-navlink5:hover {
  transform: scale(1.02);
}
.home-text21:hover {
  transform: scale(1.02);
}
.home-text23 {
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 48px;
}
@media(max-width: 1200px) {
  .home-container {
    align-items: stretch;
    background-color: #000000;
  }
  .home-banner {
    height: 521px;
    opacity: 1;
    background-size: cover;
    justify-content: center;
    background-image: url("/playground_assets/nuclear-1500w.jpg");
  }
  .home-text {
    color: var(--dl-color-gray-black);
    height: 212px;
    font-size: 3rem;
    align-self: center;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .home-text01 {
    font-size: 5rem;
  }
  .home-navlink {
    align-self: center;
    background-color: var(--dl-color-primary-500);
  }
  .home-text07 {
    color: var(--dl-color-primary-700);
  }
  .home-text08 {
    color: var(--dl-color-primary-700);
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
}
@media(max-width: 991px) {
  .home-navlink {
    height: 91px;
  }
  .home-features {
    height: 849px;
  }
  .home-text07 {
    text-align: center;
  }
  .home-text08 {
    text-align: center;
  }
  .home-container1 {
    grid-template-columns: 1fr 1fr;
  }
  .home-gallery {
    height: 824px;
    grid-template-columns: 1fr 1fr;
  }
  .home-text10 {
    max-width: 100%;
  }
  .home-hero {
    padding: 48px;
    flex-direction: column;
  }
  .home-image {
    order: 2;
  }
  .home-container2 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 32px;
  }
  .home-text18 {
    text-align: center;
  }
  .home-text19 {
    text-align: center;
  }
  .home-text23 {
    text-align: center;
    padding-left: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 767px) {
  .home-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-text {
    height: 364px;
  }
  .home-features {
    height: 1092px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-text07 {
    text-align: center;
  }
  .home-gallery {
    height: 681px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-banner1 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-text10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-hero {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-image {
    width: 80%;
  }
}
@media(max-width: 479px) {
  .home-banner {
    right: -6px;
    bottom: -739px;
    height: 820px;
    padding-top: 48px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 48px;
  }
  .home-text {
    width: auto;
    height: auto;
  }
  .home-features {
    height: 1588px;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-container1 {
    grid-template-columns: 1fr;
  }
  .home-gallery {
    height: 600px;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-banner1 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-hero {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-container2 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-text23 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
</style>
