<template>
  <footer class="site-footer-footer" v-bind:class="rootClassName">
    <img :alt="image_alt" :src="image_src" class="site-footer-image" />
    <span class="site-footer-text">{{ text }}</span>
    <a
      href="https://twitter.com/OpifexPower"
      target="_blank"
      rel="noreferrer noopener"
      class="site-footer-link"
    >
      <svg viewBox="0 0 950.8571428571428 1024" class="site-footer-icon">
        <path
          d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
        ></path>
      </svg>
    </a>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  props: {
    rootClassName: String,
    image_src: {
      type: String,
      default: '/playground_assets/opifex%20industrieshq-1500h.png',
    },
    image_alt: {
      type: String,
      default: 'logo',
    },
    text: {
      type: String,
      default: '© 2023 Opifex Industries, All Rights Reserved.',
    },
  },
}
</script>

<style scoped>
.site-footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.site-footer-image {
  height: 2rem;
  object-fit: cover;
}
.site-footer-link {
  display: contents;
}
.site-footer-icon {
  fill: var(--dl-color-primary-500);
  width: 33px;
  height: 33px;
  border-color: var(--dl-color-gray-500);
  border-style: solid;
  margin-right: 32px;
  text-decoration: none;
}





.site-footer-root-class-name5 {
  min-width: 100%;
}




@media(max-width: 767px) {
  .site-footer-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
  .site-footer-text {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .site-footer-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .site-footer-image {
    margin-bottom: var(--dl-space-space-unit);
  }
  .site-footer-text {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
</style>
