<template>
  <div class="feature-card-tech-feature-card" v-bind:class="rootClassName">
    <h2 class="feature-card-tech-text">{{ title }}</h2>
    <span class="feature-card-tech-text1">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: 'FeatureCardTech',
  props: {
    title: {
      type: String,
      default: 'Technology',
    },
    rootClassName: String,
    description: {
      type: String,
      default:
        'Our state of the art technological developments accelerate humanity to a greater future.',
    },
    image_src: {
      type: String,
      default: 'https://cdn-icons-png.flaticon.com/128/1087/1087927.png',
    },
  },
}
</script>

<style scoped>
.feature-card-tech-feature-card {
  width: 100%;
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
}
.feature-card-tech-text {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card-tech-text1 {
  color: var(--dl-color-gray-700);
  text-align: center;
}

@media(max-width: 1200px) {
  .feature-card-tech-text1 {
    color: var(--dl-color-gray-black);
  }
}
</style>
