<template>
  <div class="warren-container">
    <site-header rootClassName="site-header-root-class-name4"></site-header>
    <div class="warren-container1">
      <div class="warren-container2">
        <h1 class="warren-text">Warren Experimental Power Plant</h1>
      </div>
      <span class="warren-text1">
        <span class="warren-text2">
          Opifex Pennsylvania Experimental Plant was the first experimental plant,
          it was constructed in Warren, PA, and connected to the grid in 2011. It
          generates power using the X56-Type Spherical Core, which is at the
          centre of the facility.
        </span>
        <br class="warren-text3" />
        <br class="warren-text4" />
        <span class="warren-text5">
          The Warren Plant is constructed of four floors, each floor serves a
          different purpose and contains different sections essential to plant
          operation.
        </span>
      </span>
      <img
        alt="image"
        src="/playground_assets/limerick-generating-station-1500w.png"
        class="warren-image"
      />
    </div>
    <footer class="warren-footer">
      <a
        href="https://opifex.industries/"
        target="_blank"
        rel="noreferrer noopener"
        class="warren-link"
      >
        <img
          alt="logo"
          src="/playground_assets/opifex%20industrieshq-1500h.png"
          class="warren-image1"
        />
      </a>
      <span class="warren-text6">
        © 2021 Opifex Industries, All Rights Reserved.
      </span>
      <a
        href="https://twitter.com/OpifexPower"
        target="_blank"
        rel="noreferrer noopener"
        class="warren-link1"
      >
        <svg viewBox="0 0 950.8571428571428 1024" class="warren-icon">
          <path
            d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
          ></path>
        </svg>
      </a>
    </footer>
  </div>
</template>

<script>
import SiteHeader from '../components/site-header'

export default {
  name: 'Warren',
  components: {
    SiteHeader,
  },
  metaInfo: {
    title: 'Warren Experimental Power Plant',
    meta: [
      {
        name: 'description',
        content: 'The first and ONLY Fusion power plant in the world.',
      },
      {
        property: 'og:title',
        content: 'Warren Experimental Power Plant',
      },
      {
        property: 'og:description',
        content: 'The first and ONLY Fusion power plant in the world.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.warren-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.warren-container1 {
  width: 1372px;
  height: 785px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.warren-container2 {
  flex: 0 0 auto;
  width: 608px;
  height: 71px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.warren-text {
  align-self: flex-start;
}
.warren-text1 {
  font-size: 20px;
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
}
.warren-text2 {
  font-size: 30px;
}
.warren-text3 {
  font-size: 20px;
}
.warren-text4 {
  font-size: 30px;
}
.warren-text5 {
  font-size: 30px;
}
.warren-image {
  width: 100%;
  height: 454px;
  align-self: center;
  object-fit: cover;
}
.warren-footer {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.warren-link {
  display: contents;
}
.warren-image1 {
  height: 2rem;
  object-fit: cover;
  text-decoration: none;
}
.warren-link1 {
  display: contents;
}
.warren-icon {
  fill: var(--dl-color-primary-500);
  width: 33px;
  height: 33px;
  border-color: var(--dl-color-gray-500);
  border-style: solid;
  margin-right: 32px;
  text-decoration: none;
}
@media(max-width: 1200px) {
  .warren-container1 {
    width: 100%;
  }
}
@media(max-width: 991px) {
  .warren-container1 {
    width: 100%;
    height: 899px;
  }
}
@media(max-width: 767px) {
  .warren-container1 {
    width: 100%;
    height: 931px;
  }
  .warren-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
  .warren-text6 {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .warren-container1 {
    width: 100%;
    height: 1180px;
  }
  .warren-container2 {
    width: 479px;
    height: 101px;
  }
  .warren-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .warren-image1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .warren-text6 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
</style>
