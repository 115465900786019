<template>
  <div class="mc-gregor-container">
    <site-header rootClassName="site-header-root-class-name"></site-header>
    <div class="mc-gregor-container1">
      <div class="mc-gregor-container2">
        <h1 class="mc-gregor-text">Opifex McGregor Research Facility</h1>
      </div>
      <span class="mc-gregor-text1">
        The McGregor Research Facility is the Headquarters of the Opifex Research
        Division. All kinds of innovative scientific fields are studied here, from
        Theoretical Physics, to Biotechnology, and even Nuclear Fusion research.
        We select the best and brightest of applicants to work here with us to
        innovate for the future.
      </span>
      <img
        alt="image"
        src="/playground_assets/mcgregor-1500w.jpg"
        class="mc-gregor-image"
      />
    </div>
    <site-footer rootClassName="site-footer-root-class-name1"></site-footer>
  </div>
</template>

<script>
import SiteHeader from '../components/site-header'
import SiteFooter from '../components/site-footer'

export default {
  name: 'McGregor',
  components: {
    SiteHeader,
    SiteFooter,
  },
  metaInfo: {
    title: 'McGregor Research Facility',
    meta: [
      {
        name: 'description',
        content: 'The leading research complex in Texas',
      },
      {
        property: 'og:title',
        content: 'McGregor Research Facility',
      },
      {
        property: 'og:description',
        content: 'Striving for sustainable energy.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.mc-gregor-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.mc-gregor-container1 {
  width: 1372px;
  height: 785px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mc-gregor-container2 {
  flex: 0 0 auto;
  width: 608px;
  height: 71px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.mc-gregor-text {
  align-self: flex-start;
}
.mc-gregor-text1 {
  font-size: 30px;
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
}
.mc-gregor-image {
  width: 100%;
  height: 513px;
  align-self: center;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .mc-gregor-container1 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .mc-gregor-container1 {
    height: 876px;
  }
}
@media(max-width: 479px) {
  .mc-gregor-container1 {
    height: 926px;
  }
  .mc-gregor-container2 {
    width: 479px;
    height: 144px;
  }
  .mc-gregor-text {
    width: 479px;
    height: 146px;
  }
}
</style>
