import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import NOTFOUND from './views/notfound'
import ProductShowcaseFizzler from './views/product-showcase-fizzler'
import ArticleNetZero2025 from './views/article-net-zero2025'
import Contact from './views/contact'
import ArticleProblemWithPower from './views/article-problem-with-power'
import Warren from './views/warren'
import Team from './views/team'
import Haines from './views/haines'
import McGregor from './views/mc-gregor'
import Home from './views/home'
import ProductShowcaseTemplate from './views/product-showcase-template'
import ArticleTemplate from './views/article-template'
import ProductShowcaseConduit from './views/product-showcase-conduit'
import ProductShowcaseFunnel from './views/product-showcase-funnel'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'NOTFOUND',
      path: '**',
      component: NOTFOUND,
    },
    {
      name: 'ProductShowcaseFizzler',
      path: '/product/fizzler',
      component: ProductShowcaseFizzler,
    },
    {
      name: 'Article-NetZero2025',
      path: '/article/netzero',
      component: ArticleNetZero2025,
    },
    {
      name: 'Contact',
      path: '/contact',
      component: Contact,
    },
    {
      name: 'Article-ProblemWithPower',
      path: '/article/problemwithpower',
      component: ArticleProblemWithPower,
    },
    {
      name: 'Warren',
      path: '/facilities/warren',
      component: Warren,
    },
    {
      name: 'Team',
      path: '/team',
      component: Team,
    },
    {
      name: 'Haines',
      path: '/facilities/haines',
      component: Haines,
    },
    {
      name: 'McGregor',
      path: '/facilities/mc-gregor',
      component: McGregor,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'ProductShowcaseTemplate',
      path: '/product/template',
      component: ProductShowcaseTemplate,
    },
    {
      name: 'ArticleTemplate',
      path: '/article/template',
      component: ArticleTemplate,
    },
    {
      name: 'ProductShowcaseConduit',
      path: '/product/template1',
      component: ProductShowcaseConduit,
    },
    {
      name: 'ProductShowcaseFunnel',
      path: '/product/funnel',
      component: ProductShowcaseFunnel,
    },
  ],
})
