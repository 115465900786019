<template>
  <div class="product-showcase-fizzler-container">
    <site-header rootClassName="site-header-root-class-name11"></site-header>
    <h1 class="product-showcase-fizzler-title">Contraband Destruction Field</h1>
    <div class="product-showcase-fizzler-div">
      <DangerousHTML
        html="<iframe width='560' height='315' src='https://www.youtube.com/embed/G1N7hBgu2Nk' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen></iframe>"
      ></DangerousHTML>
    </div>
    <span class="product-showcase-fizzler-body">
      <span>
        Opifex Industries is proud to introduce the Contraband Destruction Field,
        a revolutionary security technology that could change the way contraband
        items are dealt with. Also known as the Fizzler, it is an energy field
        device that can be used to instantly destroy unauthorized objects, making
        it an ideal solution for facilities with high security requirements.
      </span>
      <br />
      <br />
      <span>
        The Fizzler is activated by the provided computer program, which sends a
        signal to the device to emit a highly concentrated energy field. This
        field can be adjusted to target specific materials or objects, allowing
        for precise destruction of contraband items while leaving surrounding
        objects unharmed.
      </span>
      <br />
      <br />
      <span>
        Opifex Industries has conducted successful tests of the Fizzler in a
        variety of settings, including correctional facilities, airports, and
        high-security government buildings. The device has proven to be highly
        effective in detecting and destroying unauthorized items, including
        weapons, drugs, and even electronics.
      </span>
      <br />
      <br />
      <span>
        One of the major advantages of the Fizzler is its ability to operate
        quickly and discreetly, without the need for manual intervention. This
        makes it an ideal solution for facilities with high volumes of foot
        traffic, such as airports or stadiums.
      </span>
      <br />
      <br />
      <span>
        Opifex Industries is currently in talks with government agencies and
        private companies about implementing the Fizzler in a variety of security
        settings. With its potential to greatly enhance security measures and
        prevent contraband from entering restricted areas, the Contraband
        Destruction Field could be a game-changer for security technology.
      </span>
    </span>
    <site-footer rootClassName="site-footer-root-class-name7"></site-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import SiteHeader from '../components/site-header'
import SiteFooter from '../components/site-footer'

export default {
  name: 'ProductShowcaseFizzler',
  components: {
    SiteHeader,
    DangerousHTML,
    SiteFooter,
  },
  metaInfo: {
    title: 'ProductShowcaseFizzler - Opifex',
    meta: [
      {
        name: 'description',
        content:
          'Opifex Industries strives to provide clean, reliable energy to the USA.',
      },
      {
        property: 'og:title',
        content: 'ProductShowcaseFizzler - Opifex',
      },
      {
        property: 'og:description',
        content: 'Striving for sustainable energy.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.product-showcase-fizzler-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-black);
}
.product-showcase-fizzler-title {
  color: var(--dl-color-primary-100);
  font-size: 4em;
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
  text-transform: uppercase;
}
.product-showcase-fizzler-div {
  width: 584px;
}
.product-showcase-fizzler-body {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-fourunits);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
@media(max-width: 1200px) {
  .product-showcase-fizzler-container {
    background-color: var(--dl-color-gray-black);
  }
  .product-showcase-fizzler-title {
    color: var(--dl-color-primary-100);
    font-size: 3em;
    text-align: center;
    text-transform: uppercase;
  }
  .product-showcase-fizzler-div {
    align-self: center;
  }
  .product-showcase-fizzler-body {
    color: var(--dl-color-gray-white);
  }
}
</style>
