<template>
  <div class="product-showcase-conduit-container">
    <site-header rootClassName="site-header-root-class-name13"></site-header>
    <h1 class="product-showcase-conduit-title">Photonic Energy Conduit</h1>
    <div class="product-showcase-conduit-div">
      <DangerousHTML
        html="<iframe width='560' height='315' src='https://www.youtube.com/embed/G1N7hBgu2Nk' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen></iframe>"
      ></DangerousHTML>
    </div>
    <span class="product-showcase-conduit-body">
      <span>
        Opifex Industries is proud to introduce our latest innovation in energy
        transportation technology: the Photonic Energy Conduit. This breakthrough
        technology will revolutionize the way we transmit electrical power over
        long distances, making it more efficient, reliable, and cost-effective
        than ever before.
      </span>
      <br />
      <br />
      <span>
        Traditional methods of power transmission rely on electrical cables, which
        suffer from resistance and energy loss over long distances. This can lead
        to voltage drops and power outages, especially during peak demand periods.
        However, with the Photonic Energy Conduit, we have developed a laser-like
        beam that can transport electrical power over long distances with minimal
        energy loss.
      </span>
      <br />
      <br />
      <span>
        With this technology, Opifex Industries can transmit electrical power over
        thousands of miles. This means that power can be generated at remote
        locations, such as wind farms or solar arrays, and transmitted to areas
        with high demand, such as cities or industrial centers. This will greatly
        increase the efficiency of our power grid and reduce our dependence on
        fossil fuels.
      </span>
      <br />
      <br />
      <span>
        Opifex Industries is currently exploring ways to integrate this technology
        into our existing power grid, as well as partnering with energy providers
        to develop new sources of renewable energy. We believe that the Photonic
        Energy Conduit is a game-changing technology that will enable us to
        achieve a more sustainable and clean energy future.
      </span>
      <br />
      <br />
      <span>
        At Opifex Industries, we are committed to innovation and pushing the
        boundaries of what is possible. We are excited to continue exploring new
        and cutting-edge technologies that will help us achieve a brighter, more
        sustainable future.
      </span>
    </span>
    <site-footer rootClassName="site-footer-root-class-name9"></site-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import SiteHeader from '../components/site-header'
import SiteFooter from '../components/site-footer'

export default {
  name: 'ProductShowcaseConduit',
  components: {
    SiteHeader,
    DangerousHTML,
    SiteFooter,
  },
  metaInfo: {
    title: 'ProductShowcaseConduit - Opifex',
    meta: [
      {
        name: 'description',
        content: "you're not supposed to be here",
      },
      {
        property: 'og:title',
        content: 'ProductShowcaseConduit - Opifex',
      },
      {
        property: 'og:description',
        content: 'Striving for sustainable energy.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.product-showcase-conduit-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-black);
}
.product-showcase-conduit-title {
  color: var(--dl-color-primary-100);
  font-size: 4em;
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
  text-transform: uppercase;
}
.product-showcase-conduit-div {
  width: 584px;
}
.product-showcase-conduit-body {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-fourunits);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
@media(max-width: 1200px) {
  .product-showcase-conduit-container {
    background-color: var(--dl-color-gray-black);
  }
  .product-showcase-conduit-title {
    color: var(--dl-color-primary-100);
    font-size: 4em;
    text-transform: uppercase;
  }
  .product-showcase-conduit-div {
    align-self: center;
  }
  .product-showcase-conduit-body {
    color: var(--dl-color-gray-white);
  }
}
</style>
