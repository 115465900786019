<template>
  <div class="team-container">
    <site-header rootClassName="site-header-root-class-name3"></site-header>
    <div class="team-testimonial">
      <img
        alt="image"
        src="/playground_assets/emmetwhitfield-300h.jpeg"
        class="team-image"
      />
      <div class="team-testimonial1">
        <div class="team-container1">
          <svg viewBox="0 0 950.8571428571428 1024" class="team-icon">
            <path
              d="M438.857 548.571v219.429c0 60.571-49.143 109.714-109.714 109.714h-219.429c-60.571 0-109.714-49.143-109.714-109.714v-402.286c0-161.143 131.429-292.571 292.571-292.571h36.571c20 0 36.571 16.571 36.571 36.571v73.143c0 20-16.571 36.571-36.571 36.571h-36.571c-80.571 0-146.286 65.714-146.286 146.286v18.286c0 30.286 24.571 54.857 54.857 54.857h128c60.571 0 109.714 49.143 109.714 109.714zM950.857 548.571v219.429c0 60.571-49.143 109.714-109.714 109.714h-219.429c-60.571 0-109.714-49.143-109.714-109.714v-402.286c0-161.143 131.429-292.571 292.571-292.571h36.571c20 0 36.571 16.571 36.571 36.571v73.143c0 20-16.571 36.571-36.571 36.571h-36.571c-80.571 0-146.286 65.714-146.286 146.286v18.286c0 30.286 24.571 54.857 54.857 54.857h128c60.571 0 109.714 49.143 109.714 109.714z"
            ></path>
          </svg>
        </div>
        <span class="team-text">
          <span>
            Emmett Whitfield is a co-founder of Opifex Industries and the current
            Power-Division Supervisor at the Warren facility.
          </span>
          <br />
        </span>
        <div class="team-container2">
          <svg viewBox="0 0 950.8571428571428 1024" class="team-icon02">
            <path
              d="M438.857 182.857v402.286c0 161.143-131.429 292.571-292.571 292.571h-36.571c-20 0-36.571-16.571-36.571-36.571v-73.143c0-20 16.571-36.571 36.571-36.571h36.571c80.571 0 146.286-65.714 146.286-146.286v-18.286c0-30.286-24.571-54.857-54.857-54.857h-128c-60.571 0-109.714-49.143-109.714-109.714v-219.429c0-60.571 49.143-109.714 109.714-109.714h219.429c60.571 0 109.714 49.143 109.714 109.714zM950.857 182.857v402.286c0 161.143-131.429 292.571-292.571 292.571h-36.571c-20 0-36.571-16.571-36.571-36.571v-73.143c0-20 16.571-36.571 36.571-36.571h36.571c80.571 0 146.286-65.714 146.286-146.286v-18.286c0-30.286-24.571-54.857-54.857-54.857h-128c-60.571 0-109.714-49.143-109.714-109.714v-219.429c0-60.571 49.143-109.714 109.714-109.714h219.429c60.571 0 109.714 49.143 109.714 109.714z"
            ></path>
          </svg>
        </div>
      </div>
      <span class="team-text03">Emmett Whitfield</span>
      <span class="team-text04">
        <span>Opifex co-founder</span>
        <br />
      </span>
    </div>
    <div class="team-testimonial2">
      <img
        alt="image"
        src="/playground_assets/oscarlindstrom-300h.jpeg"
        class="team-image1"
      />
      <div class="team-testimonial3">
        <div class="team-container3">
          <svg viewBox="0 0 950.8571428571428 1024" class="team-icon04">
            <path
              d="M438.857 548.571v219.429c0 60.571-49.143 109.714-109.714 109.714h-219.429c-60.571 0-109.714-49.143-109.714-109.714v-402.286c0-161.143 131.429-292.571 292.571-292.571h36.571c20 0 36.571 16.571 36.571 36.571v73.143c0 20-16.571 36.571-36.571 36.571h-36.571c-80.571 0-146.286 65.714-146.286 146.286v18.286c0 30.286 24.571 54.857 54.857 54.857h128c60.571 0 109.714 49.143 109.714 109.714zM950.857 548.571v219.429c0 60.571-49.143 109.714-109.714 109.714h-219.429c-60.571 0-109.714-49.143-109.714-109.714v-402.286c0-161.143 131.429-292.571 292.571-292.571h36.571c20 0 36.571 16.571 36.571 36.571v73.143c0 20-16.571 36.571-36.571 36.571h-36.571c-80.571 0-146.286 65.714-146.286 146.286v18.286c0 30.286 24.571 54.857 54.857 54.857h128c60.571 0 109.714 49.143 109.714 109.714z"
            ></path>
          </svg>
        </div>
        <span class="team-text07">
          <span>
            Oscar Lindstrom is the current director of the Opifex Eastern
            Continental Branch (Generally referred to as ‘Opifex East’). He is
            in-charge of over three hundred Power Plants, from Coal, to Wind,
            Solar, and Experimental.
          </span>
          <br />
          <span>
            Oscar Lindstrom joined Opifex Industry Power in 2001 as a senior
            marketing associate, and quickly rose to become Director of Opifex
            East in 2014.
          </span>
          <br />
        </span>
        <div class="team-container4">
          <svg viewBox="0 0 950.8571428571428 1024" class="team-icon06">
            <path
              d="M438.857 182.857v402.286c0 161.143-131.429 292.571-292.571 292.571h-36.571c-20 0-36.571-16.571-36.571-36.571v-73.143c0-20 16.571-36.571 36.571-36.571h36.571c80.571 0 146.286-65.714 146.286-146.286v-18.286c0-30.286-24.571-54.857-54.857-54.857h-128c-60.571 0-109.714-49.143-109.714-109.714v-219.429c0-60.571 49.143-109.714 109.714-109.714h219.429c60.571 0 109.714 49.143 109.714 109.714zM950.857 182.857v402.286c0 161.143-131.429 292.571-292.571 292.571h-36.571c-20 0-36.571-16.571-36.571-36.571v-73.143c0-20 16.571-36.571 36.571-36.571h36.571c80.571 0 146.286-65.714 146.286-146.286v-18.286c0-30.286-24.571-54.857-54.857-54.857h-128c-60.571 0-109.714-49.143-109.714-109.714v-219.429c0-60.571 49.143-109.714 109.714-109.714h219.429c60.571 0 109.714 49.143 109.714 109.714z"
            ></path>
          </svg>
        </div>
      </div>
      <span class="team-text12">Oscar Lindstrom</span>
      <span class="team-text13">Director of Opifex-East</span>
    </div>
    <footer class="team-footer">
      <a
        href="https://opifex.industries/"
        target="_blank"
        rel="noreferrer noopener"
        class="team-link"
      >
        <img
          alt="logo"
          src="/playground_assets/opifex%20industrieshq-1500h.png"
          class="team-image2"
        />
      </a>
      <span class="team-text14">
        © 2021 Opifex Industries, All Rights Reserved.
      </span>
      <a
        href="https://twitter.com/OpifexPower"
        target="_blank"
        rel="noreferrer noopener"
        class="team-link1"
      >
        <svg viewBox="0 0 950.8571428571428 1024" class="team-icon08">
          <path
            d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
          ></path>
        </svg>
      </a>
    </footer>
  </div>
</template>

<script>
import SiteHeader from '../components/site-header'

export default {
  name: 'Team',
  components: {
    SiteHeader,
  },
  metaInfo: {
    title: 'Team - Opifex',
    meta: [
      {
        name: 'description',
        content:
          'Opifex Industries strives to provide clean, reliable energy to the USA.',
      },
      {
        property: 'og:title',
        content: 'Team - Opifex',
      },
      {
        property: 'og:description',
        content: 'Striving for sustainable energy.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c0a86adc-c4cb-4a6b-8ae6-12306ca18025/7080e2f5-7664-4e95-89a6-40e03303ebb3?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.team-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.team-testimonial {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.team-image {
  width: 288px;
  height: 288px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.team-testimonial1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.team-container1 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.team-icon {
  width: 48px;
  margin-bottom: -6rem;
}
.team-text {
  font-size: 1.15rem;
  max-width: 600px;
  margin-top: 48px;
  text-align: center;
  padding-left: 32px;
  margin-bottom: 48px;
  padding-right: 32px;
}
.team-container2 {
  display: flex;
  align-self: flex-end;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.team-icon02 {
  width: 48px;
  margin-top: -6rem;
}
.team-text03 {
  font-size: 1.5rem;
  max-width: 600px;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.team-text04 {
  max-width: 600px;
  text-align: center;
  font-weight: 600;
}
.team-testimonial2 {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.team-image1 {
  width: 288px;
  height: 288px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.team-testimonial3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.team-container3 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.team-icon04 {
  width: 48px;
  margin-bottom: -6rem;
}
.team-text07 {
  font-size: 1.15rem;
  max-width: 600px;
  margin-top: 48px;
  text-align: center;
  padding-left: 32px;
  margin-bottom: 48px;
  padding-right: 32px;
}
.team-container4 {
  display: flex;
  align-self: flex-end;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.team-icon06 {
  width: 48px;
  margin-top: -6rem;
}
.team-text12 {
  font-size: 1.5rem;
  max-width: 600px;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.team-text13 {
  max-width: 600px;
  text-align: center;
  font-weight: 600;
}
.team-footer {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.team-link {
  display: contents;
}
.team-image2 {
  height: 2rem;
  object-fit: cover;
  text-decoration: none;
}
.team-link1 {
  display: contents;
}
.team-icon08 {
  fill: var(--dl-color-primary-500);
  width: 33px;
  height: 33px;
  border-color: var(--dl-color-gray-500);
  border-style: solid;
  margin-right: 32px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .team-image {
    margin-bottom: 0px;
  }
  .team-text {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .team-image1 {
    margin-bottom: 0px;
  }
  .team-text07 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}
@media(max-width: 767px) {
  .team-testimonial {
    padding-left: 32px;
    padding-right: 32px;
  }
  .team-testimonial2 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .team-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
  .team-text14 {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .team-testimonial {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .team-image {
    width: 200px;
  }
  .team-icon {
    margin-bottom: 0px;
  }
  .team-text {
    width: auto;
  }
  .team-icon02 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .team-testimonial2 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .team-image1 {
    width: 200px;
  }
  .team-icon04 {
    margin-bottom: 0px;
  }
  .team-text07 {
    width: auto;
  }
  .team-icon06 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .team-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .team-image2 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .team-text14 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
</style>
